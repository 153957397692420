import { autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Filter, FilterActivateParams } from '@wsb_dev/datafi-shared/lib/types/Filter';
import cloneDeep from 'lodash.clonedeep';
import { EventAggregator } from 'aurelia-event-aggregator';
import { EventAggregatorWrapper } from '../../util/events/eventAggregatorWrapper';
import { subscribe } from '../../util/decorators/subscribe';

export interface DateOptions {
    mode: string;
    name: string;
    altInput: boolean;
    altFormat: string;
    dateFormat: string;
    disableMobile: boolean;
}
@subscribe({
    events: [
        { eventEmitter: 'eaw', event: 'update-filter-chips', fn: 'setfiltersWithValue' },
    ],
})
@autoinject
export class FilterDialog {

    filters: Filter[] = [];
    filtersWithValue: number;

    constructor(
        private dialogController: DialogController,
        private ea: EventAggregator,
        private eaw: EventAggregatorWrapper,
    ) { }

    activate(model: FilterActivateParams) {
        this.filters = cloneDeep(model.filterProperties);
        this.filters.forEach((filter, i) => {
            filter.open = filter.open !== undefined ? filter.open : (i < 5) || !(this.filters.length > 14);
        });
        this.setfiltersWithValue();
    }

    setfiltersWithValue() {
        this.filtersWithValue = this.filters?.filter((f) => {
            return f.value && !(Array.isArray(f.value) && f.value.length === 0) && (f.type !== 'number' || f.value.first?.value);
        }).length;
    }

    submit() {
        return this.dialogController.ok(this.filters);
    }

    resetFilter() {
        this.filters?.forEach((filter) => {
            if (filter.type === 'object') {
                Object.keys(filter.value).forEach((index) => {
                    filter.value[index] = null;
                });
            }
            else if (filter.type === 'number') {
                filter.value = {
                    first: { operator: '$eq' },
                };
            }
            else {
                filter.value = [];
                if (filter.selectedDateOptions) filter.selectedDateOptions = 'single';
            }
        });
        this.filtersWithValue = 0;
        this.ea.publish('update-filter-chips', this.filters);
    }
}
