import { Filter } from '@wsb_dev/datafi-shared/lib/types';
import { bindable } from 'aurelia-framework';
import { EventAggregatorWrapper } from 'util/events/eventAggregatorWrapper';
import { subscribe } from '../../util/decorators/subscribe';
import { EventAggregator } from 'aurelia-event-aggregator';

@subscribe({
    events: [
        // Triggered in the filter-chips and filter-dialog components
        { eventEmitter: 'eaw', event: 'update-filter-chips', fn: 'setChips' },
    ],
})
export class FilterChips {
    @bindable filters: Filter[] = [];
    chipFilters: Filter[] = [];

    operatorStrings = {
        $lt: 'less than',
        $lte: 'less than or equal to',
        $gt: 'greater than',
        $gte: 'greater than or equal to',
        $eq: 'equal to',
        $not: 'not equal to',
    };

    constructor(
        private ea: EventAggregator,
        private eaw: EventAggregatorWrapper,
    ) { }

    attached() {
        this.setChips();
    }

    setChips() {
        const chipFilters = this.filters.filter((f) => this.filterHasValue(f));
        chipFilters.forEach((f) => f['chipText'] = this.getValueText(f));
        this.chipFilters = chipFilters;

    }

    filterHasValue(filter: Filter) {
        const hasGPSValue = filter.name === 'hasGPS' ? !!filter.value.hasGeometry?.length : true;
        const isNotEmptyArray = !(Array.isArray(filter.value) && filter.value.length === 0);
        const hasNumberValue = filter.type !== 'number' || !!filter.value?.first?.value;

        return !!filter.value && hasGPSValue && isNotEmptyArray && hasNumberValue;
    }

    getValueText(filter: Filter) {
        let displayString = '';
        switch (filter.type) {
        case 'checkbox':
            displayString = filter.value.join(' or ');
            break;
        case 'number': {
            if (typeof filter.value === 'string' && filter.value?.length) {
                return `equals ${filter.value}`;
            }

            const firstCondition = filter.value?.first;
            const secondCondition = filter.value?.second;
            const compareOperator = filter.value?.compare;

            const firstComplete = firstCondition?.operator && firstCondition?.value;
            const secondComplete = secondCondition?.operator && secondCondition?.value && compareOperator;

            if (firstComplete) {
                displayString = `${this.operatorStrings[firstCondition.operator]} ${firstCondition.value}`;
            }

            if (secondComplete) {
                displayString = `${displayString} ${compareOperator} ${this.operatorStrings[secondCondition.operator]} ${secondCondition.value}`;
            }
            break;
        }
        case 'object': {
            if (filter.value.hasGeometry?.length) return 'Yes';
            break;
        }
        default:
            displayString = filter.value;
        }
        return displayString;
    }

    clearFilter(filter: Filter) {
        if (filter.type === 'object') {
            Object.keys(filter.value).forEach((index) => {
                filter.value[index] = null;
            });
        }
        else if (filter.type === 'number') {
            filter.value = {
                first: { operator: '$eq' },
            };
        }
        else {
            filter.value = [];
            if (filter.selectedDateOption) delete filter.selectedDateOption;
        }

        this.chipFilters = this.chipFilters.filter((f) => this.filterHasValue(f));
        this.ea.publish('update-filter-chips', this.filters);
    }
}
