import { Field, Hint } from '@wsb_dev/datafi-shared/lib/types';
import { autoinject, bindable } from 'aurelia-framework';

@autoinject
export class DynamicLookup {
    @bindable label: string;
    @bindable outlined: boolean;
    @bindable value;
    @bindable currentValue;
    @bindable options: Field[];
    @bindable currentOptions: Field[];
    disabled?: boolean;
    required?: boolean;
    requiredMessage?: string;
    hints?: Hint[];

    attached() {
        this.currentOptions = this.options;
        this.currentValue = this.options.find((opt) => this.value === opt.value)?.value;
    }

    getOptionDisplay(option) {
        if (option.label) return option.label;
        else if (option.value) return option.label;
        else return option;
    }

    currentValueChanged(option) {
        if (option) {
            this.value = option;
        }
    }

    optionsChanged(options) {
        if (JSON.stringify(options) !== JSON.stringify(this.currentOptions) || !this.currentOptions) {
            this.currentOptions = options;
        }
    }
}
