import { ProgramConfig } from '@wsb_dev/datafi-shared/lib/types';
import { DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { ConfirmDialog } from '../../components/confirm-dialog/confirm-dialog';
import { DatafiProAPI } from '../../services/api/DatafiProAPI';
import { AlertService } from '../../services/util/Alert';
import { StoreManager } from '../../services/util/StoreManager';
import { ensureNumber } from '../../util/numbers/ensureNumber';

export interface DataStoreDetails {
    id: number;
    name: string;
    dataType: string;
    loadOffline: boolean;
    filter: boolean;
    survey?: string;
    fieldSelection?: string;
    geometry?: boolean;
}

@autoinject
export class ConfigStoreManager {

    programId: number;
    configs: ProgramConfig[];
    dataStoreDetails: DataStoreDetails[] = [];

    constructor(
        private api: DatafiProAPI,
        private dialogs: DialogService,
        private alerts: AlertService,
        private stores: StoreManager,
    ) { }

    async activate(params, config) {
        this.programId = ensureNumber(config.settings.programId);
        this.stores.activate(this.programId);

        await this.updateDataStoreDetails();
    }

    async updateDataStoreDetails() {
        this.dataStoreDetails = [];
        const programSurveys = await this.api.programSurveys.find({
            query: {
                program_id: this.programId,
                $select: ['id', 'title'],
            },
        }).then((res: any) => res.data);

        const serviceTypeMapping = {
            'api/v1/form-submissions': 'Survey',
            'api/v1/projects-files': 'Project Files',
            'api/v1/projects': 'Projects',
            'api/v1/programs-users-roles': 'User Location',
        };

        this.stores?.stores.map(async (store) => {
            const surveyTitle = programSurveys.find((sv) => sv.id === store.query?.survey_id)?.title;
            const fieldSelection = store.query?.$modify?.fieldSelection?.length;
            const geometry = store?.query?.$modify?.toGeoJSON;
            const loadOffline = store.query?.$client;

            this.dataStoreDetails.push({
                id: store.id,
                name: store.name,
                dataType: serviceTypeMapping[store.service.path] || store.service.path,
                survey: surveyTitle ? `: ${surveyTitle}` : '',
                fieldSelection: fieldSelection ? `${fieldSelection} of 15 selected` : 'All',
                geometry: geometry ? true : false,
                loadOffline: loadOffline ? false : true,
                filter: store.query?.metadata || store.query?.status || store.query?.$modify?.checkboxQuery || store.query?.$modify?.numberQuery ? true : false,
            });
        });
    }

    async updateMapGadgets(id: number) {
        const dashboards = await this.api.programConfigs.find({ query: { program_id: this.programId, type: 'dashboard' } }) as ProgramConfig;
        const updatePromises = dashboards.data.flatMap((dashboard) => {
            let updated = false;
            dashboard.data.items.forEach((item) => {
                if (item.config?.storeIds?.includes(id)) {
                    item.config.storeIds = item.config.storeIds.filter((storeId) => storeId !== id);
                    updated = true;
                }
                if (item.config?.mapOptions?.layers) {
                    item.config.mapOptions.layers = item.config.mapOptions.layers.filter((layer) => layer.id !== `store-${id}`);
                    updated = true;
                }
            });

            if (updated) {
                return [this.api.programConfigs.patch(dashboard.id, dashboard)];
            }
            return [];
        });

        await Promise.all(updatePromises);
    }

    delete(id: number) {
        return this.dialogs.open({
            viewModel: ConfirmDialog,
            model: {
                message: 'Are you sure you want to delete this data store? Dashboard gadgets will need to be updated to remove datastore.',
                title: 'Confirm Delete',
            } as Partial<ConfirmDialog>,
        })
            .whenClosed((result) => {
                if (result.wasCancelled) {
                    return;
                }

                this.updateMapGadgets(id);
                return this.api.programConfigs.remove(id).then(() => {
                    this.alerts.create({
                        label: 'Data store removed',
                        level: 'success',
                        dismissable: true,
                    });
                    return this.stores.updateConfigs().then(() => {
                        this.updateDataStoreDetails();
                    });
                });
            })
            .catch((e) => {
                this.alerts.create({
                    label: `Error: ${e.message}`,
                    level: 'error',
                    dismissable: true,
                });
            });
    }

}
