import { autoinject, LogManager } from 'aurelia-framework';
import { StoreManager } from '../../services/util/StoreManager';
import { SimpleStore } from '../../services/util/SimpleStore';
import { subscribe } from '../../util/decorators/subscribe';
import { colorThemes, getColorTheme } from '../../util/colorThemes/getColorTheme';
import { IDashboardChartConfig, IDashboardItem, SummaryStat } from '../../types/Dashboards';
import { groupAndFilterDates, getLabelsAndData } from '../../components/chart-editor/utils/chart-editor-utils';
import { calculateStats } from '../summary-gadget/utils/calculateStats';
import { EventAggregatorWrapper } from '../../util/events/eventAggregatorWrapper';
import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { titleize } from '@wsb_dev/datafi-shared/lib/util/strings/titleize';
import { set, unset, cloneDeep, isEqual } from 'lodash';
import { AlertService } from 'services/util/Alert';
const logger = LogManager.getLogger('dfp:chart-wrapper');
@autoinject
@subscribe({
    events: [
        { eventEmitter: 'ea', event: 'update-chart', fn: 'updateData' },
    ],
})
@subscribe({
    events: [
        { eventEmitter: 'store', event: SimpleStore.change, fn: 'onStoreChange' },
    ], attached: 'dataStoreAttached', detached: 'dataStoreDetached',
})
export class ChartWrapper {
    chartOptions: any;
    resolved = false;
    // subscriber for the event emitter
    store: SimpleStore;
    config: IDashboardChartConfig;
    id: string;
    datalabels: boolean;
    wrapperEditItem = undefined;

    constructor(
        private ea: EventAggregatorWrapper,
        private stores: StoreManager,
        private alerts: AlertService,
    ) {
    }

    unbind() {
        this.dataStoreDetached();
    }

    onStoreChange() {
        this.updateData(this.wrapperEditItem, true);
    }

    dataStoreAttached() { return; }
    dataStoreDetached() { return; }

    async activate(model) {
        this.id = model.options.id;
        this.config = model.options.config;
        // remove object reference and deep clone the original options
        this.wrapperEditItem = cloneDeep(model.options);
        this.updateData();
    }

    async updateData(item?: IDashboardItem<IDashboardChartConfig>, storeChanged?: boolean): Promise<void> {
        logger.debug('updating chart data', item);
        if (item && item.id !== this.id || item && item?.id === undefined) {
            return;
        }

        let itemDeep = cloneDeep(item);

        // // this keeps getting set to undefined somewhere and I'm not sure why
        // issues are arising where certain field properties are being dynamically set to undefined
        const unsetFields = ['config.splitField.type', 'config.statsField.type', 'config.categoryField.type',
            'config.splitField.parent', 'config.statsField.parent', 'config.categoryField.parent',
            'config.splitField.format', 'config.statsField.format', 'config.categoryField.format',
        ];
        if (itemDeep?.config?.customTitle == undefined || this.wrapperEditItem?.config?.customTitle == undefined) {
            unsetFields.push('config.customTitle');
        }

        /* might need to change this approach, we should probably look into only emitting an event if the config changes,
        because this deep checking is unreliable with these complex configurations */
        itemDeep = this.omitter(itemDeep, unsetFields);
        this.wrapperEditItem = this.omitter(this.wrapperEditItem, unsetFields);
        if (isEqual(this.wrapperEditItem, itemDeep) && !storeChanged) {
            logger.debug('no configuration changes, not updating chart');
            return;
        } else {
            this.wrapperEditItem = cloneDeep(item);
        }

        this.resolved = false;

        // get the data store
        const store = this.stores.getStore(this.config?.storeId) as SimpleStore;
        if (!store) {
            this.resolved = true;
            return;
        }

        if (this.store !== store) {
            // if its a different store, call current store detached function
            this.dataStoreDetached();
            this.store = store;
            await this.store.refresh()
                .catch((err) => {
                    this.resolved = false;
                    this.alerts.create({
                        level: 'error',
                        label: `Error fetching DataStore: ${this.store.name} (${err.message})`,
                        dismissable: true,
                    });
                });
            this.dataStoreAttached();
        }

        // ensure we don't modify/mutate the config
        const config = JSON.parse(JSON.stringify(this.config));
        this.datalabels = config?.datalabels;

        this.chartOptions = config?.chartOptions;

        const currentField = config.categoryField || { label: 'None', name: 'none' };

        if (currentField.type !== 'date') {
            config.dateRange = '';
        }

        // this is the data that will be used for everything
        const data = currentField.type === 'date'
            ? groupAndFilterDates(cloneDeep(this.store?.data), currentField.path, config.timeInterval, config.dateRange)
            : cloneDeep(this.store?.data) || [];

        let chartjs_labels_data = undefined;

        const statsFields = (config.statsFields || this.legacyStatsFields(config)).filter((sf) => sf.field.name !== 'none');

        // if there if no y-axis values or pie chart values, return empty labels and data.
        if (!statsFields.length || !data.length) {
            chartjs_labels_data = { labels: [], data: [] };
        }
        else {
            // if there isn't a category field, handle pie chart values.
            if (currentField.name === 'none') {
                const labels = statsFields.map((sf) => `${sf.stat} of ${sf.field.label}`);
                const statsData = statsFields.map((sf) => calculateStats(data, sf.field.path, sf.stat, undefined, undefined));
                chartjs_labels_data = { labels: labels, data: [{ data: statsData }] };
            }
            else {
                const labelsAndData = getLabelsAndData(data, currentField, statsFields, config.splitField);
                chartjs_labels_data = { labels: labelsAndData.labels, data: labelsAndData.data };
            }
        }

        // pass our new data into this function that will update the chart configuration
        this.setChartData(chartjs_labels_data.labels, chartjs_labels_data.data);
        this.resolved = true;

        return;
    }

    setChartData(labels: Array<string | number>, data: any[]): void {
        logger.debug('setting chart data', labels, data);
        this.chartOptions.data.labels = labels.map((l) => l?.toString().replace(/_/g, ' '));

        this.legacyChartTheme();
        const theme = this.getChartTheme(data);

        this.setDatasets(data, theme);
        this.setXAxisConfig();
        this.setYAxisConfig();
        this.setChartTitle();
        this.setChartCutout();
        this.setDataLabels();
        this.setXAxisTicks(labels);
        this.setTooltips(labels);
        this.setXAxisDisplay();
    }

    // Functions for setting chart properties
    getChartTheme(data: any[]): Record<string, string[]> {
        const themeLength = this.config.chartOptions.type === 'bar' || data.length === 0 ? data.length : data[0].data.length;
        return getColorTheme(themeLength, this.config.chartTheme);
    }

    setDatasets(data: any[], theme: Record<string, string[]>): void {
        if (!data.length){
            this.chartOptions.data.datasets = [];
            return;
        }
        if (this.config.chartOptions.type === 'pie') {
            // single dataset
            // match each interpolated color to a datum in the dataset
            this.chartOptions.data.datasets[0].label = data[0].label;
            this.chartOptions.data.datasets[0].data = data[0].data;
            this.chartOptions.data.datasets[0].backgroundColor = theme.primary;
            this.chartOptions.data.datasets[0].color = theme.accent;
        } else {
            // multiple datasets
            // match each interpolated color to a dataset
            for (let i = 0; i <= data.length - 1; i++) {
                data[i]['backgroundColor'] = theme.primary[i];
                data[i]['color'] = theme.accent[i];
            }
            this.chartOptions.data.datasets = data;
        }
    }

    setXAxisConfig(): void {
        const categoryDate = this.config.categoryField?.type === 'date';
        // field might be missing a label, in that case use the field name
        const categoryName = this.config.categoryField?.label !== undefined ? this.config.categoryField?.label : this.config.categoryField?.name;

        const xAxisConfig = {
            x: {
                stacked: this.config.stacked,
            },
        };
        if (categoryDate) {
            xAxisConfig.x['type'] = 'time';
            xAxisConfig.x['time'] = { unit: this.config.timeInterval };
        }
        if (!categoryDate && this.config.splitField.name !== 'none') {
            xAxisConfig['xAxis2'] = {
                type: 'category',
                grid: { drawOnChartArea: false },
                title: {
                    display: true,
                    text: this.config.customXLabel && this.config.xLabel ? this.config.xLabel : categoryName,
                    font: { size: 16, weight: 'bold' },
                },
            };
        } else {
            xAxisConfig.x['title'] = {
                display: true,
                text: this.config.customXLabel && this.config.xLabel ? this.config.xLabel : categoryName,
                font: { size: 16, weight: 'bold' },
            };
        }
        set(this.chartOptions, 'options.scales', xAxisConfig);
    }

    setYAxisConfig(): void {
        const formattedStatsFields = this.config.statsFields || this.legacyStatsFields(this.config);
        const statsFields = formattedStatsFields.filter((sf) => sf.field.name !== 'none');
        const yLabel = statsFields[0]?.field.label !== undefined ?
            titleize(statsFields[0]?.field.label) :
            titleize(statsFields[0]?.field.name);
        const yField = this.config.customYLabel && this.config.yLabel ? this.config.yLabel :
            statsFields.length === 1 ? `${statsFields[0].stat} of ${yLabel}` : '';

        set(this.chartOptions, 'options.scales.y', {
            stacked: this.config.stacked, title: {
                display: true,
                text: `${yField}`,
                font: { size: 16, weight: 'bold' },
            },
        });
    }

    setChartTitle(): void {
        set(this.chartOptions, 'options.plugins.title', {
            display: true, text: this.config.title, font: { size: 20, weight: 'bold' },
        });
    }

    setChartCutout(): void {
        set(this.chartOptions, 'options.cutout', this.config.doughnut ? '50%' : 0);
    }

    setDataLabels(): void {
        if (this.datalabels) {
            const dataLabelOptions = {
                align: 'center',
                anchor: 'center',
                backgroundColor: (context) => {
                    return context.dataset.backgroundColor;
                },
                borderColor: (context) => {
                    return context.dataset.color;
                },
                borderRadius: 25,
                borderWidth: 2,

                color: (context) => {
                    return context.dataset.color;
                },
                font: {
                    weight: 'bold',
                },
                padding: 6,

            };

            if (this.config.percentLabels) {
                if (this.config.splitField.name !== 'none') {

                    // use grand total in chart for multiple datasets
                    dataLabelOptions['formatter'] = (value, context) => {
                        const grandTotal = context.chart.data.datasets.map((ds) => ds.data.reduce((a, z) => a + z)).reduce((a, z) => a + z);
                        return ((context.dataset.data[context.dataIndex] / grandTotal) * 100).toFixed(1) + '%';
                    };
                    dataLabelOptions['display'] = (context) => {
                        // if the value is bigger than this, it'll show up
                        const grandTotal = context.chart.data.datasets.map((ds) => ds.data.reduce((a, z) => a + z)).reduce((a, z) => a + z);
                        if (!((context.dataset.data[context.dataIndex] / grandTotal) * 100 > 2.5)) { return false; }
                        else { return 'auto'; }
                    };
                } else {
                    dataLabelOptions['formatter'] = (value, context) => {
                        const sum = context.dataset.data.reduce((a, b) => a + b);
                        return ((context.dataset.data[context.dataIndex] / sum) * 100).toFixed(1) + '%';
                    };
                    dataLabelOptions['display'] = (context) => {
                        // if the value is bigger than this, it'll show up
                        const sum = context.dataset.data.reduce((a, b) => a + b);
                        if (!((context.dataset.data[context.dataIndex] / sum) * 100 > 5)) { return false; }
                        else { return 'auto'; }
                    };
                }
            } else {
                // default to displaying the count
                dataLabelOptions['formatter'] = (value, context) => {
                    return context.dataset.data[context.dataIndex].toFixed(1);
                };
                dataLabelOptions['display'] = (context) => {
                    // if the value is bigger than this, it'll show up
                    if (!(context.dataset.data[context.dataIndex] > 0)) { return false; }
                    else { return 'auto'; }
                };
            }
            set(this.chartOptions, 'options.plugins.datalabels', dataLabelOptions);

        } else {
            set(this.chartOptions, 'options.plugins.datalabels', {
                // display nothing
                display: (context) => {
                    return null;
                },
            });
        }
    }

    setXAxisTicks(labels: Array<string | number>): void {
        const categoryDate = this.config.categoryField?.type === 'date';

        // this will handle labels for the bottom x-axis
        this.chartOptions.options.scales.x.ticks = {
            callback: (value, index, values) => {
                const label = labels[index]?.toString();
                const formatted = label?.split('|delimiter|')[1] || label;
                if (this.config.hiddenLabels?.includes(formatted)) return '';
                return categoryDate ? value : formatted?.replace(/_/g, ' ');
            },
        };
        // this will handle labels for the top x-axis, if it exists
        if (!categoryDate && this.config.splitField.name !== 'none') {
            this.chartOptions.options.scales.xAxis2.ticks = {
                callback: (value, index, values) => {
                    const label = labels[index]?.toString().split('|delimiter|')[0];
                    if (this.config.hiddenLabels?.includes(label)) return '';
                    const prevLabel = labels[index - 1]?.toString().split('|delimiter|')[0];
                    return label === prevLabel ? '' : label.split('|delimiter|')[0]?.replace(/_/g, ' ');
                },
            };
        }
    }

    setTooltips(labels: Array<string | number>): void {
        // handle hiding tooltip labels
        this.chartOptions.options.plugins.tooltip = {
            callbacks: {
                title: (context) => {
                    if (this.config.hideAllLabels) return '';
                    const tooltip = labels[context[0].dataIndex].toString();
                    const splitByField = tooltip.split('|delimiter|').filter((l) => !this.config.hiddenLabels?.includes(l));
                    if (this.config.hiddenLabels?.includes(context[0].label)) return '';
                    else return splitByField.join(', ').replace(/_/g, ' ');
                },
            },
        };
    }

    setXAxisDisplay(): void {
        const categoryDate = this.config.categoryField?.type === 'date';

        this.chartOptions.options.scales.x.display = !this.config.hideAllLabels;
        if (!categoryDate && this.config.splitField.name !== 'none') {
            this.chartOptions.options.scales.xAxis2.display = !this.config.hideAllLabels;
        }
    }

    //Functions for handling legacy chart properties
    legacyStatsFields(config: IDashboardChartConfig | any): [{ field: Field, stat: SummaryStat }] {
        return config.statsField?.name !== 'none' ?
            [{ field: config.statsField, stat: config.stat as SummaryStat }] :
            [{ field: { label: 'Id', name: 'id', path: 'id' }, stat: 'Count' }];
    }

    legacyChartTheme(): void {
        if (!this.config.chartTheme) { this.config.chartTheme = colorThemes[0]; }
    }

    // Function for emitting certain fields
    omitter(item, fields: string[]): any {
        fields.forEach((f) => {
            unset(item, f);
        });
        return item;
    }
}
